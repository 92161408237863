const ROUTE_RECIPIENT = '/recipient/:patientCode';

const routeConstant = {
    ROUTE_DONOR__VIEW_PDF: '/donor-view-pdf/:donorCode',

    ROUTE_PASS_DONOR__ADMINISTRATIVE:
        '/donor/:donorCode/pass/form/administrative',
    ROUTE_PASS_DONOR__FORM_LAYOUT: '/donor/:donorCode/pass/form/:formName',
    ROUTE_PASS_DONOR__ORGAN_LIST: '/donor/:donorCode/pass/organlist',
    ROUTE_PASS_DONOR__CONCLUSION: '/donor/:donorCode/pass/conclusion',
    ROUTE_PASS_DONOR__CONCLUSION__ORGAN:
        '/donor/:donorCode/pass/conclusion/organ/:organId',
    ROUTE_PASS_DONOR__SURGERY: '/donor/:donorCode/pass/surgery',

    ROUTE_RECIPIENT: ROUTE_RECIPIENT,
    ROUTE_RECIPIENT__ADMINISTRATIVE: `${ROUTE_RECIPIENT}/form/administrative`,
    ROUTE_RECIPIENT__FORM_LAYOUT: `${ROUTE_RECIPIENT}/form/:formName`,
    ROUTE_RECIPIENT__ORGAN_LIST: `${ROUTE_RECIPIENT}/organlist`,
    ROUTE_RECIPIENT__CONCLUSION: `${ROUTE_RECIPIENT}/conclusion`,
    ROUTE_RECIPIENT__CONCLUSION__ORGAN: `${ROUTE_RECIPIENT}/conclusion/organ/:organId`,
    ROUTE_RECIPIENT__SURGERY: `${ROUTE_RECIPIENT}/surgery`,
};

export const refactorRoute = {
    DONOR_REGISTER_LIST: '/donors/register',
    DONOR_WAITING_LIST: '/donors/waiting',
    DONOR_DONATED_LIST: '/donors/donated',
    DONOR_REJECTED_LIST: '/donors/rejected',
    RECEIVER_REGISTER_LIST: 'recipient/register',
    RECEIVER_WAITING_LIST: 'recipient/waiting',
    RECEIVER_RECEIVED_LIST: 'recipient/received',
    RECEIVER_REJECTED_LIST: 'recipient/rejected',

    DONOR_DETAIL: '/donors/:donorUid/detail',
    DONOR_DETAIL_HANH_CHINH: '/donors/:donorUid/detail/hanh-chinh',
    DONOR_DETAIL_CHAN_DOAN: '/donors/:donorUid/detail/chan-doan',
    DONOR_DETAIL_CHAN_DOAN_CHUNG: '/donors/:donorUid/detail/chan-doan/chung',
    DONOR_DETAIL_CHAN_DOAN_TU_VONG:
        '/donors/:donorUid/detail/chan-doan/tu-vong',
    DONOR_DETAIL_DIEN_TIEN_LAM_SANG:
        '/donors/:donorUid/detail/dien-tien-lam-sang',
    DONOR_DETAIL_LAM_SANG:
        '/donors/:donorUid/detail/dien-tien-lam-sang/lam-sang',
    DONOR_DETAIL_ECMO: '/donors/:donorUid/detail/dien-tien-lam-sang/ecmo',
    DONOR_DETAIL_NGUNG_TUAN_HOAN:
        '/donors/:donorUid/detail/dien-tien-lam-sang/ngung-tuan-hoan',
    DONOR_DETAIL_NHIEM_KHUAN:
        '/donors/:donorUid/detail/dien-tien-lam-sang/nhiem-khuan',
    DONOR_DETAIL_KET_QUA_XET_NGHIEM:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem',
    DONOR_DETAIL_SINH_HOC_PHAN_TU:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoc-phan-tu',
    DONOR_DETAIL_HUYET_HOC_TRUYEN_MAU:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/huyet-hoc-truyen-mau',
    DONOR_DETAIL_NHOM_MAU:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/huyet-hoc-truyen-mau/nhom-mau',
    DONOR_DETAIL_TE_BAO_HOC:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/huyet-hoc-truyen-mau/te-bao-hoc',
    DONOR_DETAIL_SINH_HOA:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoa',
    DONOR_DETAIL_MAU:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoa/mau',
    DONOR_DETAIL_NUOC_TIEU:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoa/nuoc-tieu',
    DONOR_DETAIL_KHI_MAU_DONG_MACH:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoa/khi-mau-dong-mach',
    DONOR_DETAIL_DICH_NAO_TUY:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/sinh-hoa/dich-nao-tuy',
    DONOR_DETAIL_VI_SINH: '/donors/:donorUid/detail/ket-qua-xet-nghiem/vi-sinh',
    DONOR_DETAIL_HUYET_THANH:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/vi-sinh/huyet-thanh',
    DONOR_DETAIL_NUOI_CAY:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/vi-sinh/nuoi-cay',
    DONOR_DETAIL_NHUOM_GRAM:
        '/donors/:donorUid/detail/ket-qua-xet-nghiem/vi-sinh/nhuom-gram',

    DONOR_DETAIL_PDF: '/donors/:donorUid/pdf',

    PATIENT_DETAIL: '/patients/:patientUid/detail',
    PATIENT_DETAIL_HANH_CHINH: '/patients/:patientUid/detail/hanh-chinh',
    PATIENT_DETAIL_BENH_SU: '/patients/:patientUid/detail/benh-su',
    PATIENT_DETAIL_CHAN_DOAN: '/patients/:patientUid/detail/chan-doan',
    PATIENT_DETAIL_TIEN_CAN_NOI_KHOA:
        '/patients/:patientUid/detail/tien-can/noi-khoa',
    PATIENT_DETAIL_TIEN_CAN_BENH_THAN:
        '/patients/:patientUid/detail/tien-can/benh-than',
    PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA:
        '/patients/:patientUid/detail/tien-can/ngoai-khoa',
    PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA_NGOAI_KHOA:
        '/patients/:patientUid/detail/tien-can/ngoai-khoa/ngoai-khoa',
    PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA_UNG_THU:
        '/patients/:patientUid/detail/tien-can/ngoai-khoa/ung-thu',
    PATIENT_DETAIL_CAN_LAM_SANG: '/patients/:patientUid/detail/can-lam-sang',
    PATIENT_DETAIL_MIEN_DICH:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich',
    PATIENT_DETAIL_CAN_LAM_SANG_HUYET_HOC:
        '/patients/:patientUid/detail/can-lam-sang/huyet-hoc',
    PATIENT_DETAIL_NHOM_MAU:
        '/patients/:patientUid/detail/can-lam-sang/huyet-hoc/nhom-mau',
    PATIENT_DETAIL_TE_BAO_HOC:
        '/patients/:patientUid/detail/can-lam-sang/huyet-hoc/te-bao-hoc',
    PATIENT_DETAIL_HLA:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/hla',
    PATIENT_DETAIL_ANTI_HLA:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/anti-hla',
    PATIENT_DETAIL_CAN_LAM_SANG_HINH_ANH_HOC:
        '/patients/:patientUid/detail/can-lam-sang/hinh-anh-hoc',
    PATIENT_DETAIL_BILAN_TMA:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/bilan-tma',
    PATIENT_DETAIL_BILAN_LUPUS:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/bilan-lupus',
    PATIENT_DETAIL_BILAN_VIEM_GAN_TU_MIEN:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/bilan-viem-gan-tu-mien',
    PATIENT_DETAIL_ALPHA_THALASSEMIA:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/alpha-thalassemia',
    PATIENT_DETAIL_BETA_THALASSEMIA:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/beta-thalassemia',
    PATIENT_DETAIL_XET_NGHIEM_KHAC:
        '/patients/:patientUid/detail/can-lam-sang/mien-dich/xet-nghiem-khac',
    PATIENT_DETAIL_VI_SINH: '/patients/:patientUid/detail/can-lam-sang/vi-sinh',
    PATIENT_DETAIL_VI_SINH_MIEN_DICH:
        '/patients/:patientUid/detail/can-lam-sang/vi-sinh/mien-dich',
    PATIENT_DETAIL_VI_SINH_BILAN_LAO:
        '/patients/:patientUid/detail/can-lam-sang/vi-sinh/bilan-lao',
    PATIENT_DETAIL_VI_SINH_KY_SINH_TRUNG:
        '/patients/:patientUid/detail/can-lam-sang/vi-sinh/ky-sinh-trung',
    PATIENT_DETAIL_SINH_HOA:
        '/patients/:patientUid/detail/can-lam-sang/sinh-hoa',
    PATIENT_DETAIL_SINH_HOA_MAU:
        '/patients/:patientUid/detail/can-lam-sang/sinh-hoa/mau',
    PATIENT_DETAIL_SINH_HOA_NUOC_TIEU:
        '/patients/:patientUid/detail/can-lam-sang/sinh-hoa/nuoc-tieu',
    PATIENT_DETAIL_SINH_HOA_DICH_MANG_BUNG:
        '/patients/:patientUid/detail/can-lam-sang/sinh-hoa/dich-mang-bung',
    PATIENT_DETAIL_KHAM_CHUYEN_KHOA:
        '/patients/:patientUid/detail/kham-chuyen-khoa',
    PATIENT_DETAIL_GHI_CHU: '/patients/:patientUid/detail/ghi-chu',
    PATIENT_DETAIL_GIAI_PHAU_BENH:
        '/patients/:patientUid/detail/can-lam-sang/giai-phau-benh',
};

export default routeConstant;
