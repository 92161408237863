import { HashRouter, Route, Routes } from 'react-router-dom';
import GlobalTheme from './themes/components/GlobalTheme';
import routes from 'route/routes';
import CustomProvider from 'components/CustomProvider';

const renderRoute = route => {
    return (
        <Route
            key-={route.name}
            path={route.path}
            element={<route.component />}
        >
            {route.children?.map(renderRoute)}
        </Route>
    );
};

function App() {
    return (
        <>
            <CustomProvider
                theme={{
                    token: {
                        borderRadius: 2,
                        colorPrimary: '#394BC7',
                    },
                }}
            >
                <GlobalTheme />
                <HashRouter>
                    <Routes>{routes.map(renderRoute)}</Routes>
                </HashRouter>
            </CustomProvider>
        </>
    );
}
export default App;
