import 'bootstrap/dist/css/bootstrap.css';
import 'hrst-components/lib/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Provider } from 'react-redux';
import '../src/scss/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/Store';
import { Localization } from './translation';
import { InputConfigProvider } from 'hrst-components';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <InputConfigProvider
            value={{ displayFormat: 'HH:mm - DD/MM/YYYY', dataSeparator: ',' }}
        >
            <Localization>
                <App />
            </Localization>
        </InputConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
