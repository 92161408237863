import { FormName } from 'form_data/constant';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { refactorRoute } from 'route/routeConstant';
import Sidebar from 'view/DonorLayout/DeceasedDonorLayout/components/Sidebar';

const LeftMenuDonor = props => {
    const menuList = useMemo(() => {
        return [
            {
                key: 'administrative',
                title: 'Thông tin hành chính',
                link: generatePath(refactorRoute.DONOR_DETAIL_HANH_CHINH, {
                    donorUid: props?.infoData?.uid || '',
                }),
            },
            {
                key: 'diagnosis',
                title: 'Chẩn đoán',
                link: generatePath(refactorRoute.DONOR_DETAIL_CHAN_DOAN, {
                    donorUid: props?.infoData?.uid || '',
                }),
            },
            {
                key: 'clinical',
                title: 'Diễn tiến lâm sàng',
                link: generatePath(
                    refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
                    { donorUid: props?.infoData?.uid || '' }
                ),
            },
            {
                key: 'treatment',
                title: 'Chăm sóc và điều trị',
                tabs: [
                    FormName.deceased_donor__cham_soc_noi_khi_quan,
                    FormName.deceased_donor__cham_soc_ecmo,
                    FormName.deceased_donor__cham_soc_khi_mau_dong_mach,
                    FormName.deceased_donor__cham_soc_khi_mau_tinh_mach,
                    FormName.deceased_donor__cham_soc_ngung_tim,
                    FormName.deceased_donor__cham_soc_thuoc_van_mach,
                ],
            },
            {
                key: 'test-results',
                title: 'Kết quả xét nghiệm',
                subItems: [
                    {
                        key: 'molecularBiology',
                        title: 'Sinh học phân tử',
                        link: generatePath(
                            refactorRoute.DONOR_DETAIL_SINH_HOC_PHAN_TU,
                            { donorUid: props?.infoData?.uid || '' }
                        ),
                    },
                    {
                        key: 'huyet_hoc_truyen_mau',
                        title: 'Huyết học truyền máu',
                        link: generatePath(
                            refactorRoute.DONOR_DETAIL_HUYET_HOC_TRUYEN_MAU,
                            { donorUid: props?.infoData?.uid || '' }
                        ),
                        isRefactored: true,
                    },
                    {
                        key: 'hoa_sinh',
                        title: 'Sinh hóa',
                        link: generatePath(
                            refactorRoute.DONOR_DETAIL_SINH_HOA,
                            { donorUid: props?.infoData?.uid || '' }
                        ),
                        isRefactored: true,
                    },
                    {
                        key: 'vi_sinh',
                        title: 'Vi sinh',
                        link: generatePath(refactorRoute.DONOR_DETAIL_VI_SINH, {
                            donorUid: props?.infoData?.uid || '',
                        }),
                        isRefactored: true,
                    },
                ],
            },
            {
                key: 'hinh-anh-hoc',
                title: 'Hình ảnh học',
                tabs: [FormName.deceased_donor__hinh_anh_hoc],
            },
            {
                key: 'conclusion',
                title: 'Kết luận',
                tabs: [FormName.deceased_donor__ket_luan],
            },
            {
                key: 'surgery',
                title: 'Thông tin về cuộc mổ',
                tabs: [FormName.deceased_donor__thong_tin_cuoc_mo],
            },
        ];
    }, [props?.infoData?.uid]);

    return (
        <Sidebar
            titleName={props?.infoData.full_name}
            menuList={menuList}
            isHideMenu={props?.isHideMenu}
            donorInfo={props?.infoData}
        />
    );
};

export default LeftMenuDonor;
