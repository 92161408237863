import { memo } from 'react';
import Loadable from 'react-loadable';
import LoadingRoutes from 'utils/Components/LoadingRoutes';
import Redirect from './components/Redirect';
import { refactorRoute } from './routeConstant';

const Loading = memo(() => {
    return <LoadingRoutes />;
});
Loading.displayName = 'Loading';

const LoaderBuilder = (loader: () => Promise<any>) =>
    Loadable({ loader, loading: Loading });

const patientDetailRoutes = [
    {
        name: 'Hanh Chinh',
        path: refactorRoute.PATIENT_DETAIL_HANH_CHINH,
        component: LoaderBuilder(() => import('view/PatientDetail/HanhChinh')),
    },
    {
        name: 'Benh Su',
        path: refactorRoute.PATIENT_DETAIL_BENH_SU,
        component: LoaderBuilder(() => import('view/PatientDetail/BenhSu')),
    },
    {
        name: 'Chan Doan',
        path: refactorRoute.PATIENT_DETAIL_CHAN_DOAN,
        component: LoaderBuilder(() => import('view/PatientDetail/ChanDoan')),
    },
    {
        name: 'Tien Can Benh Than',
        path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_BENH_THAN,
        component: LoaderBuilder(
            () => import('view/PatientDetail/TienCan/BenhThan')
        ),
    },
    {
        name: 'Tien Can Noi Khoa',
        path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_NOI_KHOA,
        component: LoaderBuilder(
            () => import('view/PatientDetail/TienCan/NoiKhoa')
        ),
    },
    {
        name: 'Tien Can Ngoai Khoa',
        path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA,
        component: LoaderBuilder(
            () => import('view/PatientDetail/TienCan/NgoaiKhoa')
        ),
        children: [
            {
                name: 'Default',
                path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA,
                component: () => (
                    <Redirect
                        to={
                            refactorRoute.PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA_NGOAI_KHOA
                        }
                    />
                ),
            },
            {
                name: 'Ngoai Khoa',
                path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA_NGOAI_KHOA,
                component: LoaderBuilder(
                    () =>
                        import('view/PatientDetail/TienCan/NgoaiKhoa/NgoaiKhoa')
                ),
            },
            {
                name: 'Ung Thu',
                path: refactorRoute.PATIENT_DETAIL_TIEN_CAN_NGOAI_KHOA_UNG_THU,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/TienCan/NgoaiKhoa/UngThu')
                ),
            },
        ],
    },
    {
        name: 'Can Lam Sang',
        path: refactorRoute.PATIENT_DETAIL_CAN_LAM_SANG,
        component: LoaderBuilder(() => import('view/PatientDetail/CanLamSang')),
        children: [
            {
                name: 'Default',
                path: refactorRoute.PATIENT_DETAIL_CAN_LAM_SANG,
                component: () => (
                    <Redirect to={refactorRoute.PATIENT_DETAIL_MIEN_DICH} />
                ),
            },
            {
                name: 'Mien Dich',
                path: refactorRoute.PATIENT_DETAIL_MIEN_DICH,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/CanLamSang/MienDich')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.PATIENT_DETAIL_MIEN_DICH,
                        component: () => (
                            <Redirect to={refactorRoute.PATIENT_DETAIL_HLA} />
                        ),
                    },
                    {
                        name: 'HLA',
                        path: refactorRoute.PATIENT_DETAIL_HLA,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/HLA'
                                )
                        ),
                    },
                    {
                        name: 'Anti HLA',
                        path: refactorRoute.PATIENT_DETAIL_ANTI_HLA,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/AntiHLAandCPRA'
                                )
                        ),
                    },
                    {
                        name: 'Bilan TMA',
                        path: refactorRoute.PATIENT_DETAIL_BILAN_TMA,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/BilanTMA'
                                )
                        ),
                    },
                    {
                        name: 'Bilan Lupus',
                        path: refactorRoute.PATIENT_DETAIL_BILAN_LUPUS,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/BilanLupus'
                                )
                        ),
                    },
                    {
                        name: 'Bilan Viem Gan Tu Mien',
                        path: refactorRoute.PATIENT_DETAIL_BILAN_VIEM_GAN_TU_MIEN,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/BilanViemGanTuMien'
                                )
                        ),
                    },
                    {
                        name: 'Alpha Thalassemia',
                        path: refactorRoute.PATIENT_DETAIL_ALPHA_THALASSEMIA,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/AlphaThalassemia'
                                )
                        ),
                    },
                    {
                        name: 'Beta Thalassemia',
                        path: refactorRoute.PATIENT_DETAIL_BETA_THALASSEMIA,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/BetaThalassemia'
                                )
                        ),
                    },
                    {
                        name: 'Xet Nghiem Khac',
                        path: refactorRoute.PATIENT_DETAIL_XET_NGHIEM_KHAC,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/MienDich/XetNghiemKhac'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Huyet hoc',
                path: refactorRoute.PATIENT_DETAIL_CAN_LAM_SANG_HUYET_HOC,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/CanLamSang/HuyetHoc')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.PATIENT_DETAIL_CAN_LAM_SANG_HUYET_HOC,
                        component: () => (
                            <Redirect
                                to={refactorRoute.PATIENT_DETAIL_NHOM_MAU}
                            />
                        ),
                    },
                    {
                        name: 'Nhom mau',
                        path: refactorRoute.PATIENT_DETAIL_NHOM_MAU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/HuyetHoc/NhomMau'
                                )
                        ),
                    },
                    {
                        name: 'Te bao hoc',
                        path: refactorRoute.PATIENT_DETAIL_TE_BAO_HOC,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/HuyetHoc/TeBaoHoc'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Sinh Hoa',
                path: refactorRoute.PATIENT_DETAIL_SINH_HOA,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/CanLamSang/SinhHoa')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.PATIENT_DETAIL_SINH_HOA,
                        component: () => (
                            <Redirect
                                to={refactorRoute.PATIENT_DETAIL_SINH_HOA_MAU}
                            />
                        ),
                    },
                    {
                        name: 'Mau',
                        path: refactorRoute.PATIENT_DETAIL_SINH_HOA_MAU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/SinhHoa/Mau'
                                )
                        ),
                    },
                    {
                        name: 'Dich mang bung',
                        path: refactorRoute.PATIENT_DETAIL_SINH_HOA_DICH_MANG_BUNG,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/SinhHoa/DichMangBung'
                                )
                        ),
                    },
                    {
                        name: 'Nuoc Tieu',
                        path: refactorRoute.PATIENT_DETAIL_SINH_HOA_NUOC_TIEU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/SinhHoa/NuocTieu'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Vi Sinh',
                path: refactorRoute.PATIENT_DETAIL_VI_SINH,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/CanLamSang/ViSinh')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.PATIENT_DETAIL_VI_SINH,
                        component: () => (
                            <Redirect
                                to={
                                    refactorRoute.PATIENT_DETAIL_VI_SINH_MIEN_DICH
                                }
                            />
                        ),
                    },
                    {
                        name: 'Mien dich',
                        path: refactorRoute.PATIENT_DETAIL_VI_SINH_MIEN_DICH,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/ViSinh/MienDich'
                                )
                        ),
                    },
                    {
                        name: 'Bilan lao',
                        path: refactorRoute.PATIENT_DETAIL_VI_SINH_BILAN_LAO,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/ViSinh/BilanLao'
                                )
                        ),
                    },
                    {
                        name: 'ky sinh trung',
                        path: refactorRoute.PATIENT_DETAIL_VI_SINH_KY_SINH_TRUNG,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/PatientDetail/CanLamSang/ViSinh/KySinhTrung'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Giai Phau Benh',
                path: refactorRoute.PATIENT_DETAIL_GIAI_PHAU_BENH,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/CanLamSang/GiaiPhauBenh')
                ),
            },
            {
                name: 'Hinh Anh Hoc',
                path: refactorRoute.PATIENT_DETAIL_CAN_LAM_SANG_HINH_ANH_HOC,
                component: LoaderBuilder(
                    () => import('view/PatientDetail/HinhAnhHoc')
                ),
            },
        ],
    },
    {
        name: 'Kham chuyen khoa',
        path: refactorRoute.PATIENT_DETAIL_KHAM_CHUYEN_KHOA,
        component: LoaderBuilder(
            () => import('view/PatientDetail/KhamChuyenKhoa')
        ),
    },
    {
        name: 'Ghi Chu',
        path: refactorRoute.PATIENT_DETAIL_GHI_CHU,
        component: LoaderBuilder(() => import('view/PatientDetail/GhiChu')),
    },
    // {
    //     name: 'Chan Doan',
    //     path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
    //     component: LoaderBuilder(() => import('view/DonorDetail/ChanDoan')),
    //     children: [
    //         {
    //             name: 'Default',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
    //             component: () => (
    //                 <Redirect to={refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG} />
    //             ),
    //         },
    //         {
    //             name: 'Chan Doan Chung',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/ChanDoan/ChanDoanChung')
    //             ),
    //         },
    //         {
    //             name: 'Chan Doan Tu Vong',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_TU_VONG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/ChanDoan/ChanDoanTuVong')
    //             ),
    //         },
    //     ],
    // },
    // {
    //     name: 'Dien Tien Lam Sang',
    //     path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
    //     component: LoaderBuilder(
    //         () => import('view/DonorDetail/DienTienLamSang')
    //     ),
    //     children: [
    //         {
    //             name: 'Default',
    //             path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
    //             component: () => (
    //                 <Redirect to={refactorRoute.DONOR_DETAIL_LAM_SANG} />
    //             ),
    //         },
    //         {
    //             name: 'Lam Sang',
    //             path: refactorRoute.DONOR_DETAIL_LAM_SANG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/DienTienLamSang/LamSang')
    //             ),
    //         },
    //     ],
    // },
];

export default patientDetailRoutes;
