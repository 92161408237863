import { memo } from 'react';
import Loadable from 'react-loadable';
import LoadingRoutes from 'utils/Components/LoadingRoutes';
import Redirect from './components/Redirect';
import { refactorRoute } from './routeConstant';

const Loading = memo(() => {
    return <LoadingRoutes />;
});
Loading.displayName = 'Loading';

const LoaderBuilder = (loader: () => Promise<any>) =>
    Loadable({ loader, loading: Loading });

const donorDetailRoutes = [
    {
        name: 'Hanh Chinh',
        path: refactorRoute.DONOR_DETAIL_HANH_CHINH,
        component: LoaderBuilder(() => import('view/DonorDetail/HanhChinh')),
    },
    {
        name: 'Chan Doan',
        path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
        component: LoaderBuilder(() => import('view/DonorDetail/ChanDoan')),
        children: [
            {
                name: 'Default',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
                component: () => (
                    <Redirect to={refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG} />
                ),
            },
            {
                name: 'Chan Doan Chung',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/ChanDoan/ChanDoanChung')
                ),
            },
            {
                name: 'Chan Doan Tu Vong',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_TU_VONG,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/ChanDoan/ChanDoanTuVong')
                ),
            },
        ],
    },
    {
        name: 'Dien Tien Lam Sang',
        path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
        component: LoaderBuilder(
            () => import('view/DonorDetail/DienTienLamSang')
        ),
        children: [
            {
                name: 'Default',
                path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
                component: () => (
                    <Redirect to={refactorRoute.DONOR_DETAIL_LAM_SANG} />
                ),
            },
            {
                name: 'Lam Sang',
                path: refactorRoute.DONOR_DETAIL_LAM_SANG,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/DienTienLamSang/LamSang')
                ),
            },
            {
                name: 'ECMO',
                path: refactorRoute.DONOR_DETAIL_ECMO,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/DienTienLamSang/ECMO')
                ),
            },
            {
                name: 'Ngung Tuan Hoan',
                path: refactorRoute.DONOR_DETAIL_NGUNG_TUAN_HOAN,
                component: LoaderBuilder(
                    () =>
                        import('view/DonorDetail/DienTienLamSang/NgungTuanHoan')
                ),
            },
            {
                name: 'Nhiem Khuan',
                path: refactorRoute.DONOR_DETAIL_NHIEM_KHUAN,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/DienTienLamSang/NhiemKhuan')
                ),
            },
        ],
    },
    {
        name: 'Ket Qua Xet Nghiem',
        path: refactorRoute.DONOR_DETAIL_KET_QUA_XET_NGHIEM,
        component: LoaderBuilder(
            () => import('view/DonorDetail/KetQuaXetNghiem')
        ),
        children: [
            {
                name: 'Default',
                path: refactorRoute.DONOR_DETAIL_KET_QUA_XET_NGHIEM,
                component: () => (
                    <Redirect
                        to={refactorRoute.DONOR_DETAIL_KET_QUA_XET_NGHIEM}
                    />
                ),
            },
            {
                name: 'Sinh Hoat Phan Tu',
                path: refactorRoute.DONOR_DETAIL_SINH_HOC_PHAN_TU,
                component: LoaderBuilder(
                    () =>
                        import('view/DonorDetail/KetQuaXetNghiem/SinhHocPhanTu')
                ),
            },
            {
                name: 'Huyet hoc truyen mau',
                path: refactorRoute.DONOR_DETAIL_HUYET_HOC_TRUYEN_MAU,
                component: LoaderBuilder(
                    () =>
                        import(
                            'view/DonorDetail/KetQuaXetNghiem/HuyetHocTruyenMau'
                        )
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.DONOR_DETAIL_HUYET_HOC_TRUYEN_MAU,
                        component: () => (
                            <Redirect
                                to={refactorRoute.DONOR_DETAIL_NHOM_MAU}
                            />
                        ),
                    },
                    {
                        name: 'Nhom Mau',
                        path: refactorRoute.DONOR_DETAIL_NHOM_MAU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/HuyetHocTruyenMau/NhomMau'
                                )
                        ),
                    },
                    {
                        name: 'Te Bao Hoc',
                        path: refactorRoute.DONOR_DETAIL_TE_BAO_HOC,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/HuyetHocTruyenMau/TeBaoHoc'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Sinh Hoa',
                path: refactorRoute.DONOR_DETAIL_SINH_HOA,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/KetQuaXetNghiem/SinhHoa')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.DONOR_DETAIL_SINH_HOA,
                        component: () => (
                            <Redirect to={refactorRoute.DONOR_DETAIL_MAU} />
                        ),
                    },
                    {
                        name: 'Sinh Hoa',
                        path: refactorRoute.DONOR_DETAIL_MAU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/SinhHoa/Mau'
                                )
                        ),
                    },
                    {
                        name: 'Nuoc Tieu',
                        path: refactorRoute.DONOR_DETAIL_NUOC_TIEU,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/SinhHoa/NuocTieu'
                                )
                        ),
                    },
                    {
                        name: 'Khi Mau Dong Mach',
                        path: refactorRoute.DONOR_DETAIL_KHI_MAU_DONG_MACH,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/SinhHoa/KhiMauDongMach'
                                )
                        ),
                    },
                    {
                        name: 'Dich Nao Tuy',
                        path: refactorRoute.DONOR_DETAIL_DICH_NAO_TUY,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/SinhHoa/DichNaoTuy'
                                )
                        ),
                    },
                ],
            },
            {
                name: 'Vi Sinh',
                path: refactorRoute.DONOR_DETAIL_VI_SINH,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/KetQuaXetNghiem/ViSinh')
                ),
                children: [
                    {
                        name: 'Default',
                        path: refactorRoute.DONOR_DETAIL_VI_SINH,
                        component: () => (
                            <Redirect
                                to={refactorRoute.DONOR_DETAIL_HUYET_THANH}
                            />
                        ),
                    },
                    {
                        name: 'Huyet Thanh',
                        path: refactorRoute.DONOR_DETAIL_HUYET_THANH,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/ViSinh/HuyetThanh'
                                )
                        ),
                    },
                    {
                        name: 'Nuoi Cay',
                        path: refactorRoute.DONOR_DETAIL_NUOI_CAY,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/ViSinh/NuoiCay'
                                )
                        ),
                    },
                    {
                        name: 'Nhuom Gram',
                        path: refactorRoute.DONOR_DETAIL_NHUOM_GRAM,
                        component: LoaderBuilder(
                            () =>
                                import(
                                    'view/DonorDetail/KetQuaXetNghiem/ViSinh/NhuomGram'
                                )
                        ),
                    },
                ],
            },
        ],
    },
];

export default donorDetailRoutes;
